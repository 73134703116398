@import "../../../mixins";

.review-section {
  &__swiper {
    border-radius: var(--radius-common);
    
    @include mediaTablet {
      overflow: visible;
    }

    & .swiper {

      &-slide {
        @include mediaTablet {
          max-width: rem(334);
        }

        @include mediaMobile {
          max-width: rem(270);
        }

        & > div {
          max-width: unset;
        }
      }
    }
  }
}
